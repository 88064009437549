import React, { useEffect, useRef } from 'react';
import { Object3D } from 'three';
import { getWorld } from '../../redux/worldSlice';
import styles from './Container3D.module.scss';

type Props = {
  position?: number[],
  rotation?: number[],
  children: React.ReactNode,
  visible?: boolean,
}

function Container3D({ children, position = [0, 0, 0], rotation = [0, 0, 0], visible = true }: Props) {

  const containerRef = useRef<HTMLDivElement>(null)
  const objectRef = useRef<Object3D>(null)

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      objectRef.current = getWorld().addCSS3Object(containerRef.current, position, rotation)
    }

    return () => {
      // dev note: three js should do the cleanup automaticly
      // see: https://github.com/mrdoob/three.js/blob/master/examples/jsm/renderers/CSS3DRenderer.js
    }
  }, [position, rotation])

  return (
    <div className={styles.Container3D + (visible ? "" : " " + styles.hidden)} ref={containerRef}>
      {children}
    </div>
  );
}

export default Container3D;