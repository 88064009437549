import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

import enContent, { terms as enTerms } from '../data/content_en';
import fiContent, { terms as fiTerms } from '../data/content_fi';

export type Term = {
  [key: string]: string;
};

export type Terms = {
  [key: string]: Term;
};

export type Multilang = {
  [key: string]: Contents;
};

export type Link = {
  label: string;
  content?: string;
  href?: string;
  action?: { type: string; payload?: any };
};

export type Content = {
  template: string;
  label?: string;
  title?: string;
  subtitle?: string;
  body?: string;
  image?: string;
  video?: string;
  videoPreview?: string;
  links?: Link[];
  icon?: string;
};

export type Contents = {
  [key: string]: Content;
};

export type ContentStoreState = {
  language: string;
  modalOpened: boolean;
  modalContent?: string;
  contents: Contents;
};

const multilang: Multilang = {
  en: enContent,
  fi: fiContent,
};

const terms: Terms = {
  en: enTerms,
  fi: fiTerms,
};

let language = 'en';
if (window.location.hostname.includes('stalaverse.fi')) {
  language = 'fi';
}

const langCode = window.location.hash.split('#').pop();

if (langCode && multilang[language]) {
  language = langCode;
}

const initialState: ContentStoreState = {
  language,
  modalOpened: false,
  modalContent: undefined,
  contents: {
    ...multilang[language],
  },
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.modalContent = payload;
      state.modalOpened = true;
    },
    closeModal: (state) => {
      state.modalOpened = false;
    },
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      if (state.language !== payload) {
        language = payload;
        state.language = payload;
        state.contents = {
          ...multilang[payload],
        };
      }
    },
  },
});

export const contentActions = contentSlice.actions;

export const selectContentById = (id: string) => (state: RootState) => state.content.contents[id];
export const selectContentByTags = (tags: string[]) => (state: RootState) => state.content.contents[tags[0]];
export const selectModalContent = (state: RootState) => state.content.modalContent;
export const selectModalOpened = (state: RootState) => state.content.modalOpened;
export const selectLanguage = (state: RootState) => state.content.language;

export default contentSlice.reducer;

export function t(key: string, lang?: string) {
  lang = lang || language;
  return terms[lang][key] || key;
}
