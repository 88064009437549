import { Content as ContentData } from "../../redux/contentSlice";
import MediaPlayer, { MEDIA_PLAYER } from "../MediaPlayer/MediaPlayer";
import MediumInfo, {
  MEDIUM_INFO,
  MEDIUM_INFO_HORIZONTAL,
  MEDIUM_INFO_WIDE,
} from "../MediumInfo/MediumInfo";
import SmallInfo, { SMALL_INFO } from "../SmallInfo/SmallInfo";
import WelcomeInfo from "../WelcomeInfo";
import { WELCOME_INFO } from "../WelcomeInfo/WelcomeInfo";
import styles from "./Content.module.scss";

type Props = {
  content: ContentData;
};

function Content({ content }: Props) {
  if (content === undefined) {
    return null;
  }

  switch (content.template) {
    case SMALL_INFO:
      return <SmallInfo content={content} />;

    case MEDIUM_INFO:
      return <MediumInfo content={content} />;

    case MEDIUM_INFO_HORIZONTAL:
      return <MediumInfo content={content} horizontal={true} />;

    case MEDIUM_INFO_WIDE:
      return <MediumInfo content={content} wide={true} />;

    case MEDIA_PLAYER:
      return <MediaPlayer content={content} />;

    case WELCOME_INFO:
      return <WelcomeInfo content={content} />;

    default:
      break;
  }

  return <div className={styles.content}>Invalid content template</div>;
}

export default Content;
