import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import ModalContent from '../ModalContent';
import Spot from '../Spot';
import ThreeViewer from '../ThreeViewer/ThreeViewer';
import styles from './App.module.scss';
import spots from '../../data/spots.json';
import VideoScreen from '../VideoScreen';
import Container3D from '../Container3D';
import { useSelector } from 'react-redux';
import { selectUserInteracted } from '../../redux/worldSlice';

function App() {
  const userInteracted = useSelector(selectUserInteracted);

  return (
    <div className={styles.App}>
      <ThreeViewer>
        {spots.map((item, index) => (
          <Spot key={index} tags={item.tags} position={item.position} />
        ))}
        <VideoScreen position={[-1366.102173, 217.257568, 832.126953]} rotation={[0, Math.PI / 2, 0]} tags={['stage_video']} />
        <Container3D visible={!userInteracted} position={[0, 150, 1650]} rotation={[0, -Math.PI / 1.6, 0]}>
          <div className={styles.welcomeText}>
            <div className={styles.welcomeLine1}>Welcome to</div>
            <div className={styles.welcomeLine2}>Stalaverse</div>
          </div>
        </Container3D>
      </ThreeViewer>
      <Header />
      <Footer />
      <ModalContent />
    </div>
  );
}

export default App;
