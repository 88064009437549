import { EventDispatcher } from "three";

class ClickDispatcher extends EventDispatcher {
  domElement?: HTMLCanvasElement
  downX = -1
  downY = -1
  discard = true
  tolerance = 2

  constructor(domElement: HTMLCanvasElement) {
    super()
    this.domElement = domElement

    // Mouse events
    this.domElement.addEventListener('mousedown', (e) => {
      this.discard = false
      this.downX = e.clientX
      this.downY = e.offsetY
    })

    this.domElement.addEventListener('mousemove', (e) => {
      if (this.discard) {
        return
      }
      if (Math.abs(e.clientX - this.downX) > this.tolerance || Math.abs(e.clientY - this.downY) > this.tolerance) {
        this.discard = true
      }
    })

    this.domElement.addEventListener('mouseup', (e) => {
      if (this.discard) {
        return
      }
      this.dispatchEvent({ type: 'click', x: e.clientX, y: e.offsetY });
    })

    // Touch events
    this.domElement.addEventListener('touchstart', (e) => {
      this.discard = false
      this.downX = e.changedTouches[0].clientX
      this.downY = e.changedTouches[0].clientY
    })

    this.domElement.addEventListener('touchmove', (e) => {
      if (this.discard) {
        return
      }
      if (Math.abs(e.changedTouches[0].clientX - this.downX) > this.tolerance || Math.abs(e.changedTouches[0].clientY - this.downY) > this.tolerance) {
        this.discard = true
      }
    })

    this.domElement.addEventListener('touchend', (e) => {
      if (this.discard) {
        return
      }
      this.dispatchEvent({ type: 'click', x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
    })
  }
};

export { ClickDispatcher };