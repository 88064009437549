import styles from './FinishEditor.module.scss';
import { Tabs } from '@mantine/core';
import { useSelector } from 'react-redux';
import { selectFinish, selectFinishs } from '../../redux/worldSlice';
import FinishScrollView from '../FinishScrollView';
import { GROUP_GRID, GROUP_SCENE, GROUP_TRACE } from '../../data/stalatex';

function FinishEditor() {
  const finishes = useSelector(selectFinishs);
  const finish = useSelector(selectFinish);
  return (
    <div className={styles.editor}>
      <Tabs initialTab={[GROUP_SCENE, GROUP_TRACE, GROUP_GRID].indexOf(finish.group)}>
        <Tabs.Tab className={styles.tab} label={<Label name='SCENE' designer='by Harri Koskinen' />}>
          <FinishScrollView finishes={finishes.filter((item) => item.group === GROUP_SCENE)} />
        </Tabs.Tab>
        <Tabs.Tab className={styles.tab} label={<Label name='TRACE' designer='by Gert Wingårdh' />}>
          <FinishScrollView finishes={finishes.filter((item) => item.group === GROUP_TRACE)} />
        </Tabs.Tab>
        <Tabs.Tab className={styles.tab} label={<Label name='GRID' designer='by Matti Klenell' />}>
          <FinishScrollView finishes={finishes.filter((item) => item.group === GROUP_GRID)} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}

type LabelProps = {
  name: string;
  designer: string;
};

function Label({ name, designer }: LabelProps) {
  return (
    <div className={styles.label}>
      <div className={styles.name}>{name}</div>
      <div className={styles.designer}>{designer}</div>
    </div>
  );
}

export default FinishEditor;
