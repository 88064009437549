import { Space, Text, Title } from '@mantine/core';
import { Content } from '../../redux/contentSlice';
import styles from './SmallInfo.module.scss';

export const SMALL_INFO = 'SmallInfo';

type Props = {
  content: Content;
};

function SmallInfo({ content }: Props) {
  const hasTitle = !!content.title;
  const hasBody = !!content.body;

  return (
    <div className={styles.info}>
      {hasTitle && <Title order={4}>{content.title}</Title>}
      {hasTitle && <Space h='xs' />}
      {hasBody && <Text size='md'>{content.body}</Text>}
    </div>
  );
}

export default SmallInfo;
