import * as THREE from 'three';
import {
  WebGLRenderer,
  Scene,
  PerspectiveCamera,
  Clock,
  DefaultLoadingManager,
  Mesh,
  BoxGeometry,
  Raycaster,
  MeshBasicMaterial,
  AxesHelper,
  Vector3,
  DirectionalLight,
  PlaneBufferGeometry,
  Object3D,
  CircleGeometry,
  MeshStandardMaterial,
  Vector2,
  Ray,
  Intersection,
} from 'three';
import CameraControls from 'camera-controls';
import { createMaterial as createChromaKeyMaterial } from './shaders/chroma-key';
import { ClickDispatcher } from './ClickDispatcher ';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { COMPLETE_512, CubemapManager, View } from './CubemapManager';
import views from '../data/views.json';
import { Dispatch } from 'react';

CameraControls.install({ THREE });

const MAX_SPOT_DISTANCE = 2000;

const videoScreensAreas = [
  // [[min_x, min_z], [max_x, max_z]]
  [
    [-1591.707, -775.401],
    [-601.9, 1915.944],
  ],
  [
    [-601.902, -63.817],
    [576.877, 1730.617],
  ],
  [
    [-601.902, -667.185],
    [-359.146, -63.817],
  ],
];

export default class World {
  width: number = 1024;
  height: number = 768;
  sizeChanged: boolean = false;
  doRender: boolean = false;
  clock: Clock = new Clock();
  scene: Scene = new Scene();
  camera: PerspectiveCamera = new PerspectiveCamera(60, 1, 0.1, 10000);
  renderer: WebGLRenderer = new WebGLRenderer({
    // optimize
  });
  css2Renderer?: CSS2DRenderer;
  css3Renderer?: CSS3DRenderer;

  controls?: CameraControls;
  loadingManager = DefaultLoadingManager;
  navMesh?: Mesh;
  blockMesh?: Mesh;
  cursor?: Mesh;
  raycaster = new Raycaster();

  helper: AxesHelper = new AxesHelper(64);

  cube?: Mesh;

  clickDispatcher?: ClickDispatcher;

  cubemapManager: CubemapManager = new CubemapManager();

  mousePosition: Vector2 = new Vector2();

  spots: Object3D[] = [];
  videoScreens: Object3D[] = [];

  view?: View;
  rotate: boolean = false;

  first512Complete = false;

  dispatch?: Dispatch<any>;

  constructor() {
    this.init();
    this.update();
  }

  init() {
    const EPS = 1e-5;
    // in order to archive FPS look, set EPSILON for the distance to the center
    this.camera.position.set(0, 0, EPS);

    // configure camera controller
    this.controls = new CameraControls(this.camera, this.renderer.domElement);
    this.controls.minDistance = this.controls.maxDistance = 1;
    this.controls.azimuthRotateSpeed = -0.3; // negative value to invert rotation direction
    this.controls.polarRotateSpeed = -0.3; // negative value to invert rotation direction
    this.controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
    this.controls.mouseButtons.right = CameraControls.ACTION.NONE;
    this.controls.mouseButtons.middle = CameraControls.ACTION.NONE;
    this.controls.touches.two = CameraControls.ACTION.TOUCH_ZOOM;
    this.controls.touches.three = CameraControls.ACTION.NONE;
    this.controls.minZoom = 0.5;
    this.controls.maxZoom = 4;
    this.controls.saveState();

    this.cubemapManager.camera = this.camera;
    this.cubemapManager.addEventListener(COMPLETE_512, () => {
      if (!this.first512Complete) {
        this.first512Complete = true;
        if (this.view?.rotation) {
          this.controls?.rotateTo(this.view.rotation[0] + 0.2, this.view.rotation[1], false);
          this.controls?.rotateTo(this.view.rotation[0], this.view.rotation[1], true);
        }
      }
      this.updateViewPosition();
    });

    this.loadingManager.onLoad = () => {
      this.doRender = true;
    };

    this.clickDispatcher = new ClickDispatcher(this.renderer.domElement);
    this.clickDispatcher.addEventListener('click', ({ x, y }) => {
      this.clickHandler(x, y);
      this.doRender = true;
    });

    this.renderer.domElement.addEventListener('mousemove', (e) => {
      // this.moveHandler(e.clientX, e.clientY)
      this.mousePosition.set(e.clientX, e.clientY);
      this.doRender = true;
    });

    // if (process.env.NODE_ENV === 'development') {
    document.addEventListener('keypress', (event) => {
      if (event.key === 'p') {
        console.log(this.view, this.controls?.azimuthAngle, this.controls?.polarAngle);
      }
    });
    // }

    const l = new DirectionalLight();
    this.scene.add(l);

    this.cube = new Mesh(new BoxGeometry(10000, 10000, 10000), this.cubemapManager.material);
    this.scene.add(this.cube);

    // this.cursor = new Mesh(new PlaneGeometry(32, 32), new MeshBasicMaterial({ map: (new TextureLoader()).load('./maps/cursor.png'), transparent: true, side: DoubleSide }))
    // this.cursor.rotateX(-Math.PI / 2)
    // this.scene.add(this.cursor)

    // this.scene.add(this.helper)

    const fbxLoader = new FBXLoader();
    fbxLoader.load('/models/SM_Stala_NavMesh.fbx', (group) => {
      for (const object of group.children) {
        if (object instanceof Mesh) {
          if (object.name === 'SM_Stala_NavMesh') {
            object.material = new MeshStandardMaterial({
              color: 0x00ffff,
              wireframe: false,
              transparent: false,
              opacity: 1,
              visible: false,
            });
            this.navMesh = object;
          } else if (object.name === 'SM_Stala_NavMesh_Blocker') {
            object.material = new MeshStandardMaterial({
              color: 0xff00ff,
              wireframe: false,
              transparent: false,
              opacity: 1,
              visible: false,
            });
            this.blockMesh = object;
          } else if (object.name === 'SM_Stala_NavMesh_Pointer') {
            this.cursor = object;
            this.cursor.material = new MeshBasicMaterial({ color: 0xffffff });
            this.scene.add(this.cursor);
          }
        }
      }
      this.scene.add(group);
    });

    // Plane as nav mesh
    // this.navMesh = new Mesh(new PlaneBufferGeometry(10000, 10000), new MeshBasicMaterial({ color: 0x00FFFF, visible: false }))
    // this.navMesh.rotation.x = -Math.PI / 2
    // this.scene.add(this.navMesh)

    // Demo stuff
    // this.createBalls()
    // this.createVideoElement()
    // this.createVideoCaptureElement()

    setInterval(() => {
      this.checkCanvasSize();
    }, 500);
  }

  createVideoElement() {
    const video = document.createElement('video');
    video.src = '/videos/transition7.mp4';
    video.play();
    video.loop = true;
    const texture = new THREE.VideoTexture(video);
    texture.format = THREE.RGBAFormat;
    const material = createChromaKeyMaterial(texture);
    const mesh = new THREE.Mesh(new PlaneBufferGeometry(56, 85.4), material);
    mesh.position.fromArray([0.0, 150.0, 1200.0]);
    mesh.rotation.y = 90;
    this.scene.add(mesh);
  }

  createVideoCaptureElement() {
    const webcam = document.createElement('video');

    var constraints = { audio: false, video: { width: 1920, height: 1080 } };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (mediaStream) {
        webcam.srcObject = mediaStream;
        webcam.onloadedmetadata = function (e) {
          webcam.setAttribute('autoplay', 'true');
          webcam.setAttribute('playsinline', 'true');
          webcam.play();
        };
      })
      .catch(function (err) {
        alert(err.name + ': ' + err.message);
      });

    const texture = new THREE.VideoTexture(webcam);
    const material = createChromaKeyMaterial(texture);
    const divider = 4;
    const mesh = new THREE.Mesh(new PlaneBufferGeometry(640 / divider, 480 / divider), material);
    mesh.position.fromArray([-50.0, 150.0, 1200.0]);
    mesh.rotation.y = 90;
    this.scene.add(mesh);

    const mesh2 = new THREE.Mesh(
      // new PlaneBufferGeometry(640 / divider, 480 / divider),
      new CircleGeometry(50, 50),
      new MeshBasicMaterial({ map: texture })
    );
    mesh2.position.fromArray([100, 0, -200]);
    mesh2.rotation.y = 0;

    this.scene.add(mesh2);
  }

  createBalls() {
    for (let i = 0; i < views.length; i++) {
      const view = views[i] as View;
      const m = new AxesHelper(16);
      m.position.set(view.position[0], 0, view.position[2]);
      this.scene.add(m);
    }
  }

  clickHandler(x: number, y: number) {
    if (!this.navMesh || !this.blockMesh) {
      return;
    }

    const { width, height } = this.renderer.domElement.getBoundingClientRect();

    this.raycaster.setFromCamera(
      {
        x: (x / width) * 2 - 1,
        y: -(y / height) * 2 + 1,
      },
      this.camera
    );

    const intersects = this.raycaster.intersectObjects([this.navMesh, this.blockMesh]);

    if (intersects.length && intersects[0].object === this.navMesh) {
      this.dispatch &&
        this.dispatch({
          type: 'world/requestPosition',
          payload: [intersects[0].point.x, intersects[0].point.y, intersects[0].point.z],
        });
    }
  }

  updateCursor() {
    if (!this.navMesh || !this.blockMesh || !this.cursor) {
      return;
    }

    const { width, height } = this.renderer.domElement.getBoundingClientRect();

    this.raycaster.setFromCamera(
      {
        x: (this.mousePosition.x / width) * 2 - 1,
        y: -(this.mousePosition.y / height) * 2 + 1,
      },
      this.camera
    );

    const intersects = this.raycaster.intersectObjects([this.navMesh, this.blockMesh]);

    this.cursor.visible = false;

    if (intersects.length && intersects[0].object === this.navMesh) {
      const intersection = intersects[0];
      const point = intersection.point;
      const normal = intersection.face?.normal;
      const object = intersection.object;

      if (point && normal && object) {
        this.cursor.position.copy(point);
        this.cursor.lookAt(normal.clone().applyMatrix4(object.matrixWorld).add(point));
        this.cursor.rotateX(-Math.PI / 2);
        this.cursor.visible = true;
      }
    }
  }

  checkCanvasSize() {
    const { width, height } = this.renderer.domElement.getBoundingClientRect() || {
      width: 1024,
      height: 768,
    };

    if (width !== this.width || height !== this.height) {
      this.width = width;
      this.height = height;
      this.sizeChanged = true;
    }
  }

  setView(view: View, rotate: boolean = false) {
    this.view = view;
    this.rotate = rotate;
    this.cubemapManager.setView(view);
  }

  updateViewPosition() {
    if (!this.view) {
      return;
    }
    this.controls?.moveTo(this.view.position[0], this.view.position[1], this.view.position[2], false);
    if (this.rotate && this.view.rotation) {
      this.controls?.rotateTo(this.view.rotation[0], this.view.rotation[1]);
    }
    this.cube?.position.set(this.view.position[0], this.view.position[1], this.view.position[2]);
    this.updateSpots(this.view);
    this.updateVideoScreens(this.view);
  }

  updateSpots(view: View) {
    const v = new Vector3(...view.position);
    for (let index = 0; index < this.spots.length; index++) {
      const spot = this.spots[index];
      const d = v.distanceTo(spot.position);

      if (d > MAX_SPOT_DISTANCE) {
        spot.visible = false;
        continue;
      }

      this.raycaster.ray = new Ray(v, spot.position.clone().sub(v).normalize());
      const intersects: Intersection[] = [];
      this.blockMesh?.raycast(this.raycaster, intersects);

      if (!intersects.length) {
        spot.visible = true;
        continue;
      }

      intersects.sort((a, b) => {
        return a.distance - b.distance;
      });

      if (d > intersects[0].distance) {
        spot.visible = false;
        continue;
      }

      spot.visible = true;
    }
  }

  updateVideoScreens({ position }: View) {
    let visible = false;

    for (const [min, max] of videoScreensAreas) {
      if (position[0] > min[0] && position[0] < max[0] && position[2] > min[1] && position[2] < max[1]) {
        visible = true;
        break;
      }
    }

    for (const screen of this.videoScreens) {
      screen.visible = visible;
    }
  }

  // render loop
  update() {
    window.requestAnimationFrame(() => {
      this.update();
    });

    if (this.sizeChanged) {
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.width, this.height);
      this.css2Renderer?.setSize(this.width, this.height);
      this.css3Renderer?.setSize(this.width, this.height);
      this.sizeChanged = false;
      this.doRender = true;
    }

    const delta = this.clock.getDelta();
    this.doRender = this.controls?.update(delta) || this.doRender;
    this.doRender = this.cubemapManager.update(delta) || this.doRender;

    if (this.doRender) {
      this.updateCursor();
      this.renderer.render(this.scene, this.camera);
      this.css2Renderer?.render(this.scene, this.camera);
      this.css3Renderer?.render(this.scene, this.camera);
      this.doRender = false;
    }
  }

  getCanvas() {
    return this.renderer.domElement;
  }

  createCSSRenderer(element: HTMLDivElement) {
    this.css2Renderer = new CSS2DRenderer({ element });
    this.css3Renderer = new CSS3DRenderer({ element });
  }

  addSpot(element: HTMLDivElement, position: [number, number, number]): Object3D {
    const objectCSS = this.addCSS2Object(element, position);
    this.spots.push(objectCSS);
    return objectCSS;
  }

  addVideoScreen(element: HTMLDivElement, position: [number, number, number], rotation: [number, number, number]): Object3D {
    const objectCSS = this.addCSS3Object(element, position, rotation);
    this.videoScreens.push(objectCSS);
    return objectCSS;
  }

  addCSS2Object(element: HTMLDivElement, position: [number, number, number]): Object3D {
    const objectCSS = new CSS2DObject(element);
    objectCSS.position.fromArray(position);
    this.scene.add(objectCSS);
    return objectCSS;
  }

  addCSS3Object(element: HTMLDivElement, position: [number, number, number], rotation: [number, number, number]): Object3D {
    const objectCSS = new CSS3DObject(element);
    objectCSS.position.fromArray(position);
    objectCSS.rotation.fromArray(rotation);
    this.scene.add(objectCSS);
    return objectCSS;
  }

  setDispatchFunction(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }
}
