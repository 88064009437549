import React from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/images/stala-logo.png';
import { Menu } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { contentActions, selectLanguage, t } from '../../redux/contentSlice';
import { selectView, worldActions } from '../../redux/worldSlice';

const languages = [
  { code: 'en', name: 'EN' },
  { code: 'fi', name: 'FI' },
];

const pools = ['general', 'stage', 'company', 'stalatex', 'kitchen2', 'kitchen3', 'composite', 'configurator'];

function Header() {
  const languageCode = useSelector(selectLanguage);
  const view = useSelector(selectView);
  const dispatch = useDispatch();

  return (
    <div className={styles.header}>
      <img src={logo} alt='Stala' className={styles.logo} />

      <div className={styles.center}>
        <Menu control={<div className={styles.button}>{t(view.pool)}</div>} placement='center' radius={0} size='sm'>
          {pools.map((pool, index) => (
            <Menu.Item key={index} onClick={() => dispatch(worldActions.requestPool(pool))}>
              {t(pool)}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className={styles.right}>
        <a href={t('back_href')} className={styles.link}>
          {t('back_label')}
        </a>
        <Menu
          control={<div className={styles.langButton}>{languages.find((item) => item.code === languageCode)?.name}</div>}
          placement='end'
          radius={0}
          size='xs'
          defaultValue={languageCode}
        >
          {languages.map((language, index) => (
            <Menu.Item key={index} onClick={() => dispatch(contentActions.setLanguage(language.code))}>
              {language.name}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
}

export default Header;
