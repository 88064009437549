import { Modal } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { contentActions, selectContentById, selectModalContent, selectModalOpened } from '../../redux/contentSlice';
import Content from '../Content';

import styles from './ModalContent.module.scss';

function ModalContent() {
  const dispatch = useDispatch();
  const id = useSelector(selectModalContent) || 'invalid';
  const content = useSelector(selectContentById(id));
  const opened = useSelector(selectModalOpened);

  return (
    <>
      <Modal className={styles.modal} opened={opened} onClose={() => dispatch(contentActions.closeModal())} centered size='centered'>
        {!!content && <Content content={content} />}
      </Modal>
    </>
  );
}

export default ModalContent;
