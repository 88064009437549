import { BackSide, CubeTexture, ShaderMaterial } from 'three';

export const vertexShader = /* glsl */ `
varying vec3 vWorldDirection;

vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
	return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
}

void main()	{
	vWorldDirection = transformDirection( position, modelMatrix );
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
	gl_Position.z = gl_Position.w; // set z to camera.far
}
`;

export const fragmentShader = /* glsl */ `
varying vec3 vWorldDirection;

uniform samplerCube cube0;
uniform samplerCube cube1;
uniform float phase;

void main()	{
	vec3 coord = vec3( -1.0 * vWorldDirection.x, vWorldDirection.yz );	
	vec4 col0 = textureCube( cube0, coord );
	vec4 col1 = textureCube( cube1, coord );
	vec3 col = mix(col0.rgb, col1.rgb, smoothstep(0.0, 1.0, phase));
  gl_FragColor = vec4(col.rgb, 1.0);
}
`;

export const createMaterial = (cube0: CubeTexture, cube1: CubeTexture) => {
  return new ShaderMaterial({
    uniforms: {
      cube0: {
        value: cube0,
      },
      cube1: {
        value: cube1,
      },
      phase: {
        value: 0.0,
      },
    },
    vertexShader,
    fragmentShader,
    side: BackSide,
  });
};
