import { Contents } from '../redux/contentSlice';

const watchVideoLabel = 'Katso video';

const content: Contents = {
  /**
   * Spot content
   */
  composite_1: {
    template: 'MediumInfoWide',
    image: 'images/composite_fi.jpg',
    body: `Tarjoamme useita komposiittiallasvaihtoehtoja erilaisiin asennustapoihin`,
    /* - Huullosasennettuna (kuva)
- Päältä asennettuna (kuva)
- Alta-asennettuna (kuva)
- Integroituna (kuva)     */
  },
  composite_2: {
    template: 'MediumInfo',
    image: 'images/composite_video.jpg',
    title: 'Stala composite sink CEG-50B',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'composite_video' }],
  },
  composite_3: {
    template: 'MediumInfo',
    image: 'images/Essent-composite-sinks.jpg',
    title: 'Stala Essent -mallistossa täysin mustat ja värilliset komposiittialtaat',
    body: 'Stala Essent -allas on valmistettu erittäin kestävästä kvartsikomposiitista, josta 80 % on kvartsia. Kvartsikomposiitti kestää hyvin kulutusta ja kuumuutta. Altaan etu- ja takalaidasta tapahtuva kiinnitys on asennusystävällinen – kaappeja ei tarvitse loveta. Sama allas soveltuu sekä alta- että päältäasennukseen. Moderni, minimalistinen muotoilu näkyy mm. tiukkoina kulmasäteinä (R12), kapeina reunoina sekä sileinä selkeinä pintoina. Tämä tekee puhtaanapidosta helppoa ja pinnoista hygieenisiä.',
  },
  CTA_1: {
    template: 'MediumInfo',
    image: 'images/contactus.jpg',
    title: 'Ota yhteyttä',
    body: `03 882 1133
    
sink.sales@stala.com

www.stala.fi`,
  },
  Shop_1: {
    template: 'MediumInfo',
    title: 'Ostoksille',
    links: [{ label: 'Astu verkkokauppaan', href: 'https://www.stala.fi/kauppa/' }],
  },
  kitchen2_1: {
    template: 'SmallInfo',
    body: 'Ruostumattomat terästasot ja välitilalevyt StalaTex-kuoseilla tai ilman',
  },
  kitchen2_2: {
    template: 'SmallInfo',
    body: 'Ruostumattomaan terästasoon liesiaukot omien mittojen mukaan',
  },
  kitchen2_3: {
    template: 'SmallInfo',
    body: 'Ruostumattomat terästasot ja välitilalevyt omien mittojen mukaan',
  },
  kitchen2_4: {
    template: 'SmallInfo',
    body: 'Ruostumattomat terästasot altaalla tai ilman',
  },
  stalatex_1: {
    template: 'MediumInfo',
    image: 'images/stalatex_tech.jpg',
    title: 'StalaTex-teknologia Stalalta',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'stalatex_tech_video' }],
  },
  stalatex_2: {
    template: 'MediumInfo',
    image: 'images/grid.jpg',
    title: 'GRID – a patterned stainless steel collection by Matti Klenell',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'grid_video' }],
  },
  stalatex_3: {
    template: 'MediumInfo',
    image: 'images/scene.jpg',
    title: 'SCENE – a patterned stainless steel collection by Harri Koskinen',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'scene_video' }],
  },
  stalatex_4: {
    template: 'MediumInfo',
    image: 'images/trace.jpg',
    title: 'TRACE – a patterned stainless steel collection by Gert Wingårdh',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'trace_video' }],
  },
  general_1: {
    template: 'MediumInfo',
    image: 'images/responsibility.jpg',
    title: 'Stala - Mitä vastuullisuus meille merkitsee',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'responsibility_video' }],
  },
  company_1: {
    template: 'MediumInfo',
    image: 'images/stala_story.jpg',
    title: 'Stalan puolivuosisatainen tarina',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'stala_story_video' }],
  },
  One_1: {
    template: 'MediumInfo',
    image: 'https://i.ytimg.com/vi/1kNmmgdZDrM/maxresdefault.jpg',
    title: 'Stala ONE - 100% ilmastovähennetyt ruostumattomat teräsaltaat',
    body: 'STALA ONE -tuoteperhe määrittelee ympäristöystävällisyyden funktionaalisissa kodin tiloissa uudelleen. Tämä moderni allasperhe yhdistää elegantin muotoilun kestävyyteen sekä käyttäjäkeskeisyyteen. Innovaatioiden aikakausi on alkanut.',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'Stala_ONE_ilmastovahennetyt_ruostumattomat_terasaltaat' }],
  },
  One_2: {
    template: 'MediumInfo',
    image: 'images/One_story.jpg',
    title: 'Stala ONE -allasperheen taustatarina',
    body: 'Tämä video kertoo suunnittelijan näkemyksen STALA ONE -tuoteperheen takana, ja esittää allassarjan tärkeimmät ominaisuudet. STALA ONE on innovatiivinen teräsallastuoteperhe, joka on kehitetty tämän päivän vaatimuksiin, jossa vastuullisuus ja toimivuus ovat yhtä tärkeitä kuin helppo asennettavuus ja tyylikäs muotoilu.',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'Stala_ONE_Story' }],
  },
  kitchen3_1: {
    template: 'MediumInfo',
    body: `Stalalta teräsaltaat kaikkiin tarpeisiin:

- 1-, 1,5- ja 2 –altaiset vaihtoehdot

Eri asennustavat erilaisiin tasomateriaaleihin:

- Kivi → alta-asennus ja huullosasennus 
- Laminaatti → päältä asennus, hana-alueella ja/tai laskutasolla
- Massiivipuu → päältä asennus, hana-alueella ja/tai laskutasolla  
`,
  },
  kitchen3_2: {
    template: 'MediumInfo',
    body: `Värillisiä teräsaltaita on saatavissa neljässä eri värissä:

- Kulta 
- Kupari
- Bronssi
- Antrasiitti    
`,
  },
  kitchen3_3: {
    template: 'SmallInfo',
    body: 'Tarjolla erilaisia vaihtoehtoja 40-100 cm leveisiin kaappeihin.',
  },
  kitchen3_4: {
    template: 'MediumInfo',
    image: 'images/ultra.jpg',
    title: 'Ultra – altaiden ehdotonta eliittiä',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'ultra_video' }],
  },
  kitchen3_5: {
    template: 'MediumInfo',
    image: 'images/monarch.jpg',
    title: 'Alveus Monarch Collection',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'monarch_video' }],
  },

  /**
   * Modal content
   */
  composite_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/8K-CQRAVPJc',
  },
  stalatex_tech_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=QTYwurS1vYg',
  },
  grid_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=CxdN5e8YzUk',
  },
  scene_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=fgjqz69Be8Y',
  },
  trace_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=huKi_uNAuoU',
  },
  responsibility_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=YlPB3_Q-DMk',
  },
  stala_story_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=Q1zJA5QTA5I',
  },
  ultra_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/c-gf4uEdT5U',
  },
  monarch_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/LwanXeMqADo',
  },
  stage_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=J0zhaC3t8is',
    videoPreview: 'videos/stala_yritysvideo_fin_480p.mp4',
  },
  Stala_ONE_ilmastovahennetyt_ruostumattomat_terasaltaat: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=1kNmmgdZDrM',
  },
  Stala_ONE_Story: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=TZVSqbA7il4',
  },

  /* welcome: {
    template: "WelcomeInfo",
    title: "Welcome to Stalaverse (fi)",
    image: "https://picsum.photos/800/400",
    body: "Morbi dapibus, velit eget aliquet aliquet, lorem tellus malesuada lectus. Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
  },
  stage: {
    template: "MediaPlayer",
    title: "Welcome to Stalaverse (fi)",
    body: "Morbi dapibus, velit eget aliquet aliquet, lorem tellus malesuada lectus. Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/340",
    video: "https://youtu.be/qI92u6usY80",
    videoPreview: "videos/big_buck_bunny_720p_surround.mp4",
  },
  c1: {
    template: "SmallInfo",
    label: "Small info (fi)",
    title: "Small info (fi)",
    body: "Morbi dapibus, velit eget aliquet aliquet, lorem tellus malesuada lectus.",
  },
  c2: {
    template: "MediumInfo",
    label: "Medium Info (fi)",
    title: "Medium Info (fi)",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/191",
  },
  c3: {
    template: "MediumInfo",
    label: "Medium Info",
    title: "Medium Info",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/191",      
    icon: "video",
    links: [
      { label: "Watch the video", content: "c2" },
      { label: "See the finishes", action: { type: "world/openFinishEditor", payload: undefined } }
    ]
  },
  c4: {
    template: "MediumInfoHorizontal",
    label: "SCENE",
    title: "SCENE",
    subtitle: "by Harri Koskinen",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/340",
    icon: "video",
    links: [
      { label: "Watch the video", content: "v1" },
      { label: "See the finishes", action: { type: "world/openFinishEditor", payload: undefined } }
    ]
  },
  v1: {
    template: "MediaPlayer",
    image: "https://picsum.photos/340/340",
    video: "https://youtu.be/qI92u6usY80",
  } */
};

export const terms = {
  general: 'Yleistila',
  stage: 'Stage',
  company: 'Yritys',
  stalatex: 'StalaTex',
  kitchen2: 'Seitsikko -tiskipöydät',
  kitchen3: 'Teräsaltaat',
  kitchen4: 'Stala ONE',
  configurator: 'Konfiguraattori',
  composite: 'Komposiittialtaat',
  change_pattern: 'Vaihda kuosi',
  change_sink: 'Vaihda allas',
  back_href: 'https://www.stala.fi/',
  back_label: 'stala.fi',
  top: 'Päälle asennus',
  top_installation: 'Allas asennetaan työtason päälle',
  under: 'Alta asennus',
  under_installation: 'Allas asennetaan työtason alle',
};

export default content;
