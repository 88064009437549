import styles from './Footer.module.scss';
import { Button, CloseButton, Paper } from '@mantine/core';
import FinishEditor from '../FinishEditor';
import { useDispatch, useSelector } from 'react-redux';
import { selectOneEditorOpen, selectStalatexEditorOpen, selectView, worldActions } from '../../redux/worldSlice';
import { selectLanguage, t } from '../../redux/contentSlice';
import SinkEditor from '../SinkEditor';

function Footer() {
  const stalatexEditorOpened = useSelector(selectStalatexEditorOpen);
  const oneEditorOpened = useSelector(selectOneEditorOpen);
  const view = useSelector(selectView);
  // this is here just to create re render on language change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();

  return (
    <div className={styles.footer}>
      {view.pool === 'stalatex' && (
        <div className={styles.center}>
          {!stalatexEditorOpened && (
            <Button size='md' uppercase variant='white' onClick={() => dispatch(worldActions.openStalatexEditor())}>
              {t('change_pattern')}
            </Button>
          )}
          {stalatexEditorOpened && (
            <Paper>
              <FinishEditor />
              <CloseButton onClick={() => dispatch(worldActions.closeStalatexEditor())} className={styles.close} size='md' />
            </Paper>
          )}
        </div>
      )}
      {view.pool === 'configurator' && (
        <div className={styles.center}>
          {!oneEditorOpened && (
            <Button size='md' uppercase variant='white' onClick={() => dispatch(worldActions.openOneEditor())}>
              {t('change_sink')}
            </Button>
          )}
          {oneEditorOpened && (
            <Paper>
              <SinkEditor />
              <CloseButton onClick={() => dispatch(worldActions.closeOneEditor())} className={styles.close} size='md' />
            </Paper>
          )}
        </div>
      )}
    </div>
  );
}

export default Footer;
