import { Image, Space, Title, Button } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { Content, contentActions } from '../../redux/contentSlice';
import PlayButton from '../PlayButton';
import styles from './MediumInfo.module.scss';
import ReactMarkdown from 'react-markdown';

export const MEDIUM_INFO = 'MediumInfo';
export const MEDIUM_INFO_HORIZONTAL = 'MediumInfoHorizontal';
export const MEDIUM_INFO_WIDE = 'MediumInfoWide';

type Props = {
  content: Content;
  horizontal?: boolean;
  wide?: boolean;
};

function MediumInfo({ content, horizontal, wide }: Props) {
  const dispatch = useDispatch();
  const hasTitle = !!content.title;
  const hasSubtitle = !!content.subtitle;
  const hasBody = !!content.body;
  const hasLinks = !!content.links?.length;

  return (
    <div className={styles.info + (horizontal ? ' ' + styles.horizontal : '') + (wide ? ' ' + styles.wide : '')}>
      {!!content.image && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} src={content.image} alt={content.title} />
          {content.icon === 'video' && (
            <PlayButton
              onClick={() => {
                if (content.links?.length) {
                  const link = content.links[0];
                  if (link.content) {
                    dispatch(contentActions.openModal(link.content));
                  }
                  if (link.action) {
                    dispatch(link.action);
                  }
                }
              }}
            />
          )}
        </div>
      )}
      <div className={styles.main}>
        {hasTitle && <Title order={4}>{content.title}</Title>}
        {hasSubtitle && <Title order={5}>{content.subtitle}</Title>}
        {hasTitle && hasBody && <Space h='xs' />}
        {hasBody && <ReactMarkdown children={content.body || ''}></ReactMarkdown>}
        {hasLinks && (
          <div className={styles.links}>
            {content.links?.map((link, index) => (
              <Button
                key={index}
                mt='sm'
                fullWidth
                uppercase
                variant={index !== 0 ? 'default' : undefined}
                onClick={() => {
                  if (link.content) {
                    dispatch(contentActions.openModal(link.content));
                  }
                  if (link.action) {
                    dispatch(link.action);
                  }
                  if (link.href) {
                    window.open(link.href, '_blank');
                  }
                }}
              >
                {link.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MediumInfo;
