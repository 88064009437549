import { Image, Space, Text, Title, Button } from '@mantine/core';
import { useDispatch } from 'react-redux';

import { Content, contentActions } from '../../redux/contentSlice';
import { worldActions } from '../../redux/worldSlice';

import styles from './WelcomeInfo.module.scss';

export const WELCOME_INFO = 'WelcomeInfo';

type Props = {
  content: Content;
  horizontal?: boolean;
};

function WelcomeInfo({ content }: Props) {
  const dispatch = useDispatch();

  return (
    <div className={styles.WelcomeInfo}>
      <div className={styles.imageContainer}>
        <Image className={styles.image} src={content.image} width='800' height='400' />
        <div className={styles.help}>{/* <Mouse /> */}</div>
      </div>
      <Space h='lg' />
      <Title order={1}>{content.title}</Title>
      <Text size='md'>{content.body}</Text>
      <Space h='sm' />
      <Button
        size='lg'
        mt='sm'
        fullWidth
        uppercase
        onClick={() => {
          dispatch(contentActions.closeModal());
          dispatch(worldActions.rotate());
        }}
      >
        Let's Go!
      </Button>
    </div>
  );
}

export default WelcomeInfo;
