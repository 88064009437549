import ReactPlayer from 'react-player';

import { Content } from '../../redux/contentSlice';

import styles from './MediaPlayer.module.scss';

export const MEDIA_PLAYER = 'MediaPlayer';

type Props = {
  content: Content;
};

function MediaPlayer({ content }: Props) {
  return (
    <div className={styles.player}>
      <ReactPlayer
        className={styles.video}
        url={content.video}
        config={{
          youtube: {
            playerVars: {
              controls: 1,
            },
          },
        }}
      />
    </div>
  );
}

export default MediaPlayer;
