import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import contentReducer from './contentSlice';
import worldReducer, { worldActions } from './worldSlice';

export const store = configureStore({
  reducer: {
    world: worldReducer,
    content: contentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

store.dispatch(worldActions.requestPosition([-559.876038, 160, 1343.564453]));
