import React from 'react';
import styles from './PlayButton.module.scss';

type Props = {
  onClick: () => void
}

function PlayButton({ onClick }: Props) {
  return (
    <div className={styles.button} onClick={() => onClick()}>
      <div className={styles.ring}>
        <div className={styles.play} />
      </div>
    </div>
  );
}

export default PlayButton;

