import { Dispatch, ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorld, selectLoading, selectRotate, selectUserInteracted, selectView, worldActions } from '../../redux/worldSlice';
import { View } from '../../three/CubemapManager';
import styles from './ThreeViewer.module.scss';

type Props = {
  children?: ReactNode;
};

export default function ThreeViewer({ children }: Props) {
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const loading = useSelector(selectLoading);
  const view = useSelector(selectView);
  const rotate = useSelector(selectRotate);
  const userInteracted = useSelector(selectUserInteracted);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    const container: HTMLDivElement | null = mainContainerRef.current;
    const childrenContainer: HTMLDivElement | null = childrenContainerRef.current;

    /* 
    const onStart = () => {
      dispatch(worldActions.setLoading(true))
    }

    const onComplete = () => {
      dispatch(worldActions.setLoading(false))
    }

    const onObject3DClick = (e) => {
      if (e.detail && e.detail.type) {
        dispatch(e.detail)
      }
    } 
    */

    const onControlStart = () => {
      if (!userInteracted) {
        dispatch(worldActions.setUserInteracted(true));
      }
    };

    if (getWorld().getCanvas() && container) {
      const canvas = getWorld().getCanvas();
      // add listener for custom events ('onspotclick')
      // canvas.addEventListener('object3dclick', onObject3DClick)
      container.appendChild(canvas);
      getWorld().checkCanvasSize();
      getWorld().setDispatchFunction(dispatch);
      // getWorld().assetManager.addEventListener('start', onStart)
      // getWorld().assetManager.addEventListener('complete', onComplete)
      /* if (getWorld().assetManager.loading) {
        onStart()
      } */
      // dispatch({ type: 'three/update' })

      getWorld().controls?.addEventListener('controlstart', onControlStart);
    }

    if (childrenContainer) {
      getWorld().createCSSRenderer(childrenContainer);
    }

    return () => {
      if (getWorld().getCanvas() && container) {
        const canvas = getWorld().getCanvas();
        // canvas.removeEventListener('object3dclick', onObject3DClick)
        container.removeChild(canvas);
        /* getWorld().assetManager.removeEventListener('start', onStart)
        getWorld().assetManager.removeEventListener('complete', onComplete)
        dispatch(panelActions.close()) */
        getWorld().controls?.removeEventListener('controlstart', onControlStart);
      }
    };
  }, [dispatch /* , userInteracted // DEV NOTE: if userInteracted is set here it will break the spots and video screen */]);

  useEffect(() => {
    getWorld().setView(view as View, rotate);
  }, [view, rotate]);

  return (
    <div className={`${styles.ThreeViewer}${loading ? ' ' + styles.loading : ''}`} ref={mainContainerRef}>
      <div className={styles.container} ref={childrenContainerRef}>
        {children}
      </div>
      {loading && <div className={styles.curtain} />}
      {loading && (
        <div className={styles.loading}>
          <div className={styles.loadingText}>loading...</div>
        </div>
      )}
    </div>
  );
}
