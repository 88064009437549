import { Camera, CubeTexture, EventDispatcher, ImageLoader, ShaderMaterial } from 'three';
import { createMaterial } from './shaders/multicube';
import { SUPERCUBE_CUBEMAP_PATH } from '../config';

const FACE_SIZE_512 = '512';
const FACE_SIZE_1024 = '1k';
const FACE_SIZE_2048 = '2k';

export const COMPLETE_512 = 'complete_512';
export const COMPLETE_1k = 'complete_1k';
export const COMPLETE_2k = 'complete_2k';

export type Job = {
  faceIndex: number;
  partIndex: number;
  segments: number;
  url: string;
  canceled?: boolean;
};

export type JobProcess = {
  cancel?: () => void;
  skip?: boolean;
};

export type LoadingState = {
  t512: boolean[];
  t1k: boolean[];
  t2k: boolean[];
};

export type View = {
  pool: string;
  pattern?: string;
  sink?: string;
  default?: boolean;
  cubemap: string[];
  position: number[];
  rotation?: number[];
};

export type FacePart = {
  faceIndex: number;
  partIndex: number;
  segments: number;
  image: HTMLImageElement;
};

const BASE_PATH = SUPERCUBE_CUBEMAP_PATH;

/**
 * CubeCanvas
 */
export class CubeCanvas {
  // TODO: See devices max texture size
  size = 2048;

  elements: HTMLCanvasElement[] = [
    document.createElement('canvas'),
    document.createElement('canvas'),
    document.createElement('canvas'),
    document.createElement('canvas'),
    document.createElement('canvas'),
    document.createElement('canvas'),
  ];

  constructor() {
    for (const element of this.elements) {
      element.width = this.size;
      element.height = this.size;
    }

    /* const colors = ['#FF0000', '#00FF00', '#0000FF', '#FF00FF', '#FFFF00', '#00FFFF']
    colors.forEach((color, index) => {
      this.setFaceColor(index, color)
    }) */
  }

  setFaceColor(index: number, color: string) {
    const ctx = this.elements[index].getContext('2d');
    if (ctx) {
      ctx.beginPath();
      ctx.rect(0, 0, this.size, this.size);
      ctx.fillStyle = color;
      ctx.fill();
    }
  }

  drawFacePart(part: FacePart) {
    if (!part.image) {
      return;
    }
    const canvas = this.elements[part.faceIndex];
    const ctx = canvas.getContext('2d');
    const segmentSize = this.size / part.segments;
    const x = (part.partIndex % part.segments) * segmentSize;
    const y = Math.floor(part.partIndex / part.segments) * segmentSize;
    ctx?.drawImage(part.image, x, y, segmentSize, segmentSize);
  }
}

/**
 * CubemapManager
 */
export class CubemapManager extends EventDispatcher {
  cubeTextures: CubeTexture[] = [new CubeTexture(), new CubeTexture()];

  cubeCanvases: CubeCanvas[] = [new CubeCanvas(), new CubeCanvas()];

  cubeIndex: number = 0;

  loadingState: LoadingState = {
    t512: Array(6).fill(false),
    t1k: Array(24).fill(false),
    t2k: Array(96).fill(false),
  };

  queue: Job[] = [];
  runningJobs: JobProcess[] = [];

  material?: ShaderMaterial;
  camera?: Camera;
  view?: View;
  readyForTransition: boolean = false;

  constructor() {
    super();
    for (let index = 0; index < this.cubeTextures.length; index++) {
      const texture = this.cubeTextures[index];
      const elements = this.cubeCanvases[index].elements;
      // generateMipmaps is set to false for following reasons:
      // https://discourse.threejs.org/t/are-8k-textures-supported-in-three-js/10705
      texture.generateMipmaps = false;
      texture.images = elements;
      texture.needsUpdate = true;
    }

    this.material = createMaterial(this.cubeTextures[0], this.cubeTextures[1]);
  }

  createJobs(view: View, segments: number): Job[] {
    const jobs: Job[] = [];
    const size = (segments === 1 && FACE_SIZE_512) || (segments === 2 && FACE_SIZE_1024) || (segments === 4 && FACE_SIZE_2048) || 'invalid';

    for (let f = 0; f < view.cubemap.length; f++) {
      const orgUrl = view.cubemap[f];
      const baseName = orgUrl.split('.').slice(0, -1).join('.');

      for (let y = 0; y < segments; y++) {
        for (let x = 0; x < segments; x++) {
          const url = `${BASE_PATH}${baseName}_${size}_${x}_${y}.jpg`;
          jobs.push({
            faceIndex: f,
            partIndex: y * segments + x,
            url,
            segments,
          });
        }
      }
    }

    return jobs;
  }

  clearLoadingStates() {
    this.loadingState.t512.fill(false);
    this.loadingState.t1k.fill(false);
    this.loadingState.t2k.fill(false);
  }

  cancelProcesses() {
    let job: JobProcess | undefined;
    while ((job = this.runningJobs.pop())) {
      if (job.cancel) {
        job.cancel();
      }
    }
  }

  clearQueue() {
    this.queue = [];
  }

  process(job: Job, done: (error?: ErrorEvent) => void): JobProcess {
    const loadingStatus: boolean[] =
      (job.segments === 1 && this.loadingState.t512) ||
      (job.segments === 2 && this.loadingState.t1k) ||
      (job.segments === 4 && this.loadingState.t2k) ||
      [];
    const statusIndex = job.faceIndex * job.segments * job.segments + job.partIndex;

    // see if this job is allready done and s
    if (loadingStatus[statusIndex]) {
      return { skip: true };
    }

    const loader = new ImageLoader();

    const image = loader.load(
      job.url,
      (image) => {
        if (job.canceled) {
          return;
        }
        this.cubeCanvases[this.cubeIndex].drawFacePart({
          faceIndex: job.faceIndex,
          partIndex: job.partIndex,
          segments: job.segments,
          image,
        });
        loadingStatus[statusIndex] = true;
        this.cubeTextures[this.cubeIndex].needsUpdate = true;
        done();
      },
      undefined,
      (error) => {
        if (job.canceled) {
          return;
        }
        loadingStatus[statusIndex] = true;
        this.cubeTextures[this.cubeIndex].needsUpdate = true;
        done(error);
      }
    );

    return {
      cancel: () => {
        image.src = '';
        job.canceled = true;
      },
    };
  }

  next() {
    if (this.view) {
      if (this.loadingState.t512.indexOf(false) !== -1) {
        this.queue.push(...this.createJobs(this.view, 1));
        this.run();
      } else if (this.loadingState.t1k.indexOf(false) !== -1) {
        this.dispatchEvent({ type: COMPLETE_512 });
        this.readyForTransition = true;
        this.queue.push(...this.createJobs(this.view, 2));
        this.run();
      } else if (this.loadingState.t2k.indexOf(false) !== -1) {
        this.dispatchEvent({ type: COMPLETE_1k });
        this.queue.push(...this.createJobs(this.view, 4));
        this.run();
      } else {
        this.dispatchEvent({ type: COMPLETE_2k });
      }
    }
  }

  run() {
    let job: Job | undefined;
    while ((job = this.queue.pop())) {
      const jobProcess: JobProcess = this.process(job, () => {
        const index = this.runningJobs.indexOf(jobProcess);
        this.runningJobs.splice(index, 1);

        if (this.runningJobs.length === 0) {
          this.next();
        }
      });

      if (jobProcess.skip) {
        continue;
      }

      this.runningJobs.push(jobProcess);
    }
  }

  setView(view: View) {
    if (this.view === view) {
      return;
    }
    this.view = view;
    this.clearLoadingStates();
    this.cancelProcesses();
    this.clearQueue();
    this.readyForTransition = false;
    this.cubeIndex = (this.cubeIndex === 0 && 1) || 0;
    this.next();
  }

  update(delta: number): boolean {
    if (this.readyForTransition && this.material && this.material.uniforms.phase.value !== this.cubeIndex) {
      const speed = delta * 1.5;
      if (this.cubeIndex > this.material.uniforms.phase.value + speed) {
        this.material.uniforms.phase.value += speed;
      } else if (this.cubeIndex < this.material.uniforms.phase.value - speed) {
        this.material.uniforms.phase.value -= speed;
      } else {
        this.material.uniforms.phase.value = this.cubeIndex;
      }
      return true;
    }
    return false;
  }
}
