import { Contents } from '../redux/contentSlice';

const watchVideoLabel = 'Watch the video';

const content: Contents = {
  /**
   * Spot content
   */
  composite_1: {
    template: 'MediumInfoWide',
    image: 'images/composite_en.jpg',
    body: `We offer several composite sink options for different installation methods`,
    /* - Flush-mounting
- Normal insetting
- Under-mounting
- Integrated */
  },
  composite_2: {
    template: 'MediumInfo',
    image: 'images/composite_video.jpg',
    title: 'Stala composite sink CEG-50B',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'composite_video' }],
  },
  composite_3: {
    template: 'MediumInfo',
    image: 'images/Essent-composite-sinks.jpg',
    title: 'All-black and colored composite sinks available in Stala Essent collection',
    body: 'Stala Essent sinks are made of extremely durable composite material, which is 80 percent quartz. Quartz composite is hardwearing and heat resistant. Mounting is from the front and back of the sink for easy installation – no need to notch the cabinets. The sinks are suitable for under or inset mounting. Modern and minimalist in design, Stala Essent sinks have a very small radius (R12), narrow edges and sleek and smooth surfaces. This makes them easy to clean and hygienic.',
  },
  CTA_1: {
    template: 'MediumInfo',
    image: 'images/contactus.jpg',
    title: 'Contact us',
    body: `tel +358 3 882 1133
    
sink.sales@stala.com

www.stala.com`,
  },
  Shop_1: {
    template: 'MediumInfo',
    title: 'Go shopping',
    links: [{ label: 'Enter Stala Shop', href: 'https://www.stala.com/shop/' }],
  },
  kitchen2_1: {
    template: 'SmallInfo',
    body: 'Stainless steel worktops and kitchen backsplashes with or without StalaTex patterns',
  },
  kitchen2_2: {
    template: 'SmallInfo',
    body: 'Hob cutouts available for stainless steel worktops according to your own dimensions',
  },
  kitchen2_3: {
    template: 'SmallInfo',
    body: 'Made-to-measure stainless steel worktops and kitchen backsplashes according to your own dimensions',
  },
  kitchen2_4: {
    template: 'SmallInfo',
    body: 'Stainless steel worktops with or without a sink',
  },
  stalatex_1: {
    template: 'MediumInfo',
    image: 'images/stalatex_tech.jpg',
    title: 'StalaTex technology from Stala',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'stalatex_tech_video' }],
  },
  stalatex_2: {
    template: 'MediumInfo',
    image: 'images/grid.jpg',
    title: 'GRID – a patterned stainless steel collection by Matti Klenell',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'grid_video' }],
  },
  stalatex_3: {
    template: 'MediumInfo',
    image: 'images/scene.jpg',
    title: 'SCENE – a patterned stainless steel collection by Harri Koskinen',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'scene_video' }],
  },
  stalatex_4: {
    template: 'MediumInfo',
    image: 'images/trace.jpg',
    title: 'TRACE – a patterned stainless steel collection by Gert Wingårdh',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'trace_video' }],
  },
  general_1: {
    template: 'MediumInfo',
    image: 'images/responsibility.jpg',
    title: "Stala's responsibility",
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'responsibility_video' }],
  },
  company_1: {
    template: 'MediumInfo',
    image: 'images/stala_story.jpg',
    title: 'Stala - 50 years of Finnish design and skilled processing of stainless steel',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'stala_story_video' }],
  },
  kitchen3_1: {
    template: 'MediumInfo',
    body: `Stainless steel sinks for all needs from Stala:

- 1, 1,5 and 2 sink options

Different installation methods for different worktop materials:

- Stone → under-mounting and flush-mounting
- Laminate → Normal insetting with tap area and/or working surface
- Wood → Normal insetting with tap area and/or working surface
`,
  },
  kitchen3_2: {
    template: 'MediumInfo',
    body: `Colored stainless steel sinks are available in four different colors:

- Gold
- Copper
- Bronze
- Anthracite
`,
  },
  kitchen3_3: {
    template: 'SmallInfo',
    body: 'Variety of sinks available for 40-100 cm wide cabinets.',
  },
  kitchen3_4: {
    template: 'MediumInfo',
    image: 'images/ultra.jpg',
    title: 'Ultra – superior quality down to the smallest detail',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'ultra_video' }],
  },
  kitchen3_5: {
    template: 'MediumInfo',
    image: 'images/monarch.jpg',
    title: 'Alveus Monarch Collection',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'monarch_video' }],
  },
  One_1: {
    template: 'MediumInfo',
    image: 'https://i.ytimg.com/vi/1kNmmgdZDrM/maxresdefault.jpg',
    title: 'STALA ONE - the first 100% climate reduced stainless steel sinks',
    body: 'STALA ONE range redefines sustainability in functional sinks. This modern sink family harmoniously combines elegant, streamlined design with sustainability and user-centricity. A new era of innovation has dawned.',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'Stala_ONE_ilmastovahennetyt_ruostumattomat_terasaltaat' }],
  },
  One_2: {
    template: 'MediumInfo',
    image: 'images/One_story.jpg',
    title: 'STALA ONE stainless steel sinks - the background story',
    body: 'This video explains the designer’s vision behind the ONE series and shows the key features of the product family. STALA ONE is a series of innovative stainless steel sinks that have been developed for today’s requirements, where sustainability and functionality are just as important as easy installation and stylish design.',
    icon: 'video',
    links: [{ label: watchVideoLabel, content: 'Stala_ONE_Story' }],
  },

  /**
   * Modal content
   */
  composite_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/8K-CQRAVPJc',
  },
  stalatex_tech_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=R5N2U38vsbw',
  },
  grid_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=FVcUGU7_J-w',
  },
  scene_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=u7ViJwWcbCA',
  },
  trace_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=V_RlsYbBMRQ',
  },
  responsibility_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=TvpWrTdIOZU',
  },
  stala_story_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=bfVGBvPs6qY',
  },
  ultra_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/c-gf4uEdT5U',
  },
  monarch_video: {
    template: 'MediaPlayer',
    video: 'https://youtu.be/LwanXeMqADo',
  },
  stage_video: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=qI92u6usY80',
    videoPreview: 'videos/stala_yritysvideo_eng_480p.mp4',
  },
  Stala_ONE_ilmastovahennetyt_ruostumattomat_terasaltaat: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=cCLT1LiAHIU',
  },
  Stala_ONE_Story: {
    template: 'MediaPlayer',
    video: 'https://www.youtube.com/watch?v=rDMEUSPz5Oc',
  },
  /* welcome: {
    template: "WelcomeInfo",
    title: "Welcome to Stalaverse",
    image: "https://picsum.photos/800/400",
    body: "Morbi dapibus, velit eget aliquet aliquet, lorem tellus malesuada lectus. Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
  },

  c1: {
    template: "SmallInfo",
    label: "Small info",
    title: "Small info",
    body: "Morbi dapibus, velit eget aliquet aliquet, lorem tellus malesuada lectus.",
  },
  c2: {
    template: "MediumInfo",
    label: "Medium Info",
    title: "Medium Info",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/191",
  },
  c3: {
    template: "MediumInfo",
    label: "Medium Info",
    title: "Medium Info",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/191",
    icon: "video",
    links: [
      { label: "Watch the video", content: "c2" },
      { label: "See the finishes", action: { type: "world/openFinishEditor", payload: undefined } }
    ]
  },
  c4: {
    template: "MediumInfoHorizontal",
    label: "SCENE",
    title: "SCENE",
    subtitle: "by Harri Koskinen",
    body: "Nulla nec ornare odio, vel congue mi. Aenean nec est eleifend, lobortis mauris tempor, sagittis nibh. Curabitur enim dolor, tincidunt et pretium eget, viverra ac mauris.",
    image: "https://picsum.photos/340/340",
    icon: "video",
    links: [
      { label: "Watch the video", content: "v1" },
      { label: "See the finishes", action: { type: "world/openFinishEditor", payload: undefined } }
    ]
  },
  v1: {
    template: "MediaPlayer",
    image: "https://picsum.photos/340/340",
    video: "https://youtu.be/qI92u6usY80",
  } */
};

export const terms = {
  general: 'General',
  stage: 'Stage',
  company: 'Company',
  stalatex: 'StalaTex',
  kitchen2: 'Seven worktops',
  kitchen3: 'Stainless steel sinks',
  kitchen4: 'Stala ONE',
  configurator: 'Configurator',
  composite: 'Composite sinks',
  change_pattern: 'Change pattern',
  change_sink: 'Change sink',
  back_href: 'https://www.stala.com/',
  back_label: 'stala.com',
  top: 'Inset mounting',
  top_installation: 'Sink is installed on top of the worktop',
  under: 'Under mounting',
  under_installation: 'Sink is installed under the worktop',
};

export default content;
