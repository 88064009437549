import React from 'react';
import { Image } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { Check } from 'tabler-icons-react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { Finish, selectSink, worldActions } from '../../redux/worldSlice';

import styles from './SinkScrollView.module.scss';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

type Props = {
  finishes: Finish[];
};

function SinkScrollView({ finishes }: Props) {
  return (
    <ScrollMenu onWheel={onWheel} scrollContainerClassName={styles.container}>
      {finishes.map((finish, index) => (
        <Card
          key={index}
          itemId={`id-${index}`} // NOTE: itemId is required for track items
          finish={finish}
        />
      ))}
    </ScrollMenu>
  );
}

export function Card({ finish }: { finish: Finish; itemId: string }) {
  const selectedFinish = useSelector(selectSink);
  const dispatch = useDispatch();
  return (
    <div role='button' className={styles.finish} onClick={() => dispatch(worldActions.setSink(finish.index))}>
      <Image src={finish.image} width={143} height={80} className={styles.image} />
      {finish === selectedFinish && (
        <div className={styles.icon}>
          <Check size={18} color='white' />
        </div>
      )}
      <div className={styles.label}>{finish.name}</div>
    </div>
  );
}

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

export default SinkScrollView;
