import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Object3D } from 'three';
import { selectContentByTags } from '../../redux/contentSlice';
import { getWorld, selectUserInteracted } from '../../redux/worldSlice';
import Content from '../Content';
import styles from './Spot.module.scss';

type Props = {
  position: number[];
  label?: string;
  content?: string;
  tags?: string[];
};

function Spot({ position, tags }: Props) {
  const content = useSelector(selectContentByTags(tags || ['invalid']));
  const containerRef = useRef<HTMLDivElement>(null);
  const objectRef = useRef<Object3D>(null);
  const userInteracted = useSelector(selectUserInteracted);

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      objectRef.current = getWorld().addSpot(containerRef.current, position);
    }

    return () => {
      // dev note: three js should do the cleanup automaticly
      // see: https://github.com/mrdoob/three.js/blob/master/examples/jsm/renderers/CSS3DRenderer.js
    };
  }, [position]);

  return (
    <div className={styles.spot + (userInteracted ? ' ' + styles.visible : ' ' + styles.hidden)} ref={containerRef}>
      {!!content && content.label && <div className={styles.label}>{content.label}</div>}
      <div className={styles.bg} />
      <div className={styles.ring} />
      <div className={styles.dot} />
      {!!content && (
        <div className={styles.content}>
          <Content content={content} />
        </div>
      )}
    </div>
  );
}

export default Spot;
