import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Object3D } from 'three';

import { contentActions, selectContentByTags } from '../../redux/contentSlice';
import { getWorld } from '../../redux/worldSlice';

import styles from './VideoScreen.module.scss';

type Props = {
  position: number[];
  rotation?: number[];
  label?: string;
  content?: string;
  tags?: string[];
};

function VideoScreen({ position, tags, rotation = [0, 0, 0] }: Props) {
  const content = useSelector(selectContentByTags(tags || ['invalid']));
  const containerRef = useRef<HTMLDivElement>(null);
  const objectRef = useRef<Object3D>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      objectRef.current = getWorld().addVideoScreen(containerRef.current, position, rotation);
    }

    return () => {
      // dev note: three js should do the cleanup automaticly
      // see: https://github.com/mrdoob/three.js/blob/master/examples/jsm/renderers/CSS3DRenderer.js
    };
  }, [position, rotation]);

  return (
    <div
      className={styles.VideoScreen}
      ref={containerRef}
      onClick={() => {
        dispatch(contentActions.openModal(tags?.[0] || ['invalid']));
      }}
    >
      <div className={styles.bg} />
      <ReactPlayer width='100%' height='100%' url={content.videoPreview} playing={true} loop={true} muted={true} />
    </div>
  );
}

export default VideoScreen;
