import { Tabs } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { selectSink, selectSinkIndex, selectSinks, worldActions } from '../../redux/worldSlice';
import SinkScrollView from '../SinkScrollView';
import { GROUP_SINK_TOP, GROUP_SINK_UNDER } from '../../data/one';
import { t } from '../../redux/contentSlice';

import styles from './SinkEditor.module.scss';

function SinkEditor() {
  const sinks = useSelector(selectSinks);
  const sink = useSelector(selectSink);
  const sinkIndex = useSelector(selectSinkIndex);
  const dispatch = useDispatch();

  const tabIndex = [GROUP_SINK_TOP, GROUP_SINK_UNDER].indexOf(sink.group);

  const handleTabChange = (index: number) => {
    if (index !== tabIndex) {
      const n = Math.floor(sinks.length / 2);
      const i = (sinkIndex % n) + index * n;
      dispatch(worldActions.setSink(i));
    }
  };

  return (
    <div className={styles.editor}>
      <Tabs initialTab={tabIndex} onTabChange={handleTabChange}>
        <Tabs.Tab className={styles.tab} label={<Label name={t('top')} designer={t('top_installation')} />}>
          <SinkScrollView finishes={sinks.filter((item) => item.group === GROUP_SINK_TOP)} />
        </Tabs.Tab>
        <Tabs.Tab className={styles.tab} label={<Label name={t('under')} designer={t('under_installation')} />}>
          <SinkScrollView finishes={sinks.filter((item) => item.group === GROUP_SINK_UNDER)} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}

type LabelProps = {
  name: string;
  designer: string;
};

function Label({ name, designer }: LabelProps) {
  return (
    <div className={styles.label}>
      <div className={styles.name}>{name}</div>
      <div className={styles.designer}>{designer}</div>
    </div>
  );
}

export default SinkEditor;
