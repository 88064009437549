import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vector3 } from 'three';

import { View } from '../three/CubemapManager';
import World from '../three/World';
import jsonViews from '../data/views.json';
import { finishes } from '../data/stalatex';
import { sinks } from '../data/one';

import { RootState } from './store';

let world: World;

const views: View[] = jsonViews.map((view) => {
  // Add monkey patch for configurator pool items
  if (view.pool === 'configurator') {
    if (view.position[0] === 845 && view.position[2] === 654.146362) {
      return {
        ...view,
        default: true,
        rotation: [-0.015333716463802282, 0.879110491751593],
      };
    }
  }

  if (view.default) {
    switch (view.pool) {
      case 'general':
        return {
          ...view,
          rotation: [-2.0397543008351646, 1.5525489348088997],
        };
      case 'stage':
        return {
          ...view,
          rotation: [1.4398724772765745, 1.6948527537075497],
        };
      case 'company':
        return {
          ...view,
          rotation: [1.244625383026349, 1.5963168369831424],
        };
      case 'stalatex':
        return {
          ...view,
          rotation: [-2.0069089952603205, 1.5087551940424915],
        };
      case 'kitchen2':
        return {
          ...view,
          rotation: [0.3123345261603728, 1.4266419301054845],
        };
      case 'kitchen3':
        return {
          ...view,
          rotation: [-2.035914955441194, 1.3828481893381113],
        };
      case 'composite':
        return {
          ...view,
          rotation: [-0.5087981132096493, 1.486858323659291],
        };
    }
  }
  return view;
});

export const getWorld = (): World => {
  if (!world) {
    world = new World();
  }
  return world;
};

export type Finish = {
  index: number;
  id: string;
  name: string;
  group: string;
  image: string;
};

export type WorldState = {
  loading: boolean;
  finishIndex: number;
  finishes: Finish[];
  sinkIndex: number;
  sinks: Finish[];
  stalatexEditorOpen: boolean;
  oneEditorOpen: boolean;
  viewIndex: number;
  rotateIndex: number;
  rotate: boolean;
  userInteracted: boolean;
};

const initialState: WorldState = {
  loading: false,
  finishIndex: 17,
  finishes,
  sinkIndex: 18,
  sinks,
  stalatexEditorOpen: true,
  oneEditorOpen: true,
  viewIndex: 0,
  rotateIndex: 0,
  rotate: false,
  userInteracted: false,
};

const getViewIndex = (position: number[], pattern: string, sink: string): number => {
  const [x, y, z] = position;
  let minLength = Number.MAX_VALUE;
  let minIndex = -1;
  let v = new Vector3();

  for (let i = 0; i < views.length; i++) {
    const view = views[i] as View;
    if ((view.pattern && view.pattern !== pattern) || (view.sink && view.sink !== sink)) {
      continue;
    }
    v.set(view.position[0] - x, view.position[1] - y, view.position[2] - z);
    const l = v.length();
    if (l < minLength) {
      minIndex = i;
      minLength = l;
    }
  }

  return minIndex;
};

export const worldSlice = createSlice({
  name: 'world',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    openStalatexEditor: (state) => {
      state.stalatexEditorOpen = true;
    },
    closeStalatexEditor: (state) => {
      state.stalatexEditorOpen = false;
    },
    openOneEditor: (state) => {
      state.oneEditorOpen = true;
    },
    closeOneEditor: (state) => {
      state.oneEditorOpen = false;
    },
    setFinish: (state, { payload }) => {
      state.finishIndex = payload;
      state.viewIndex = getViewIndex(
        views[state.viewIndex].position,
        state.finishes[state.finishIndex].id,
        state.sinks[state.sinkIndex].id
      );
    },
    setSink: (state, { payload }) => {
      state.sinkIndex = payload;
      state.viewIndex = getViewIndex(
        views[state.viewIndex].position,
        state.finishes[state.finishIndex].id,
        state.sinks[state.sinkIndex].id
      );
    },
    requestPosition: (state, { payload }) => {
      state.rotate = false;
      state.viewIndex = getViewIndex(payload, state.finishes[state.finishIndex].id, state.sinks[state.sinkIndex].id);
    },
    requestPool: (state, { payload }: PayloadAction<string>) => {
      state.rotate = true;

      const index = views.findIndex((view) => view.pool === payload && view.default === true);
      if (index !== -1) {
        state.viewIndex = index;
      } else {
      }

      // state.viewIndex = views.findIndex((view) => view.pool === payload && view.default === true);
    },
    setUserInteracted: (state, { payload }: PayloadAction<boolean>) => {
      state.userInteracted = payload;
    },
    rotate: (state) => {
      state.rotateIndex = state.rotateIndex + 1;
    },
  },
});

export const worldActions = worldSlice.actions;

export const selectLoading = (state: RootState) => state.world.loading;
export const selectStalatexEditorOpen = (state: RootState) => state.world.stalatexEditorOpen;
export const selectOneEditorOpen = (state: RootState) => state.world.oneEditorOpen;
export const selectFinishs = (state: RootState) => state.world.finishes;
export const selectFinish = (state: RootState) => state.world.finishes[state.world.finishIndex];
export const selectSinks = (state: RootState) => state.world.sinks;
export const selectSink = (state: RootState) => state.world.sinks[state.world.sinkIndex];
export const selectSinkIndex = (state: RootState) => state.world.sinkIndex;
export const selectViewIndex = (state: RootState) => state.world.viewIndex;
export const selectView = (state: RootState) => views[state.world.viewIndex];
export const selectRotateIndex = (state: RootState) => state.world.rotateIndex;
export const selectRotate = (state: RootState) => state.world.rotate;
export const selectUserInteracted = (state: RootState) => state.world.userInteracted;

export default worldSlice.reducer;
