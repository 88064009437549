import { Finish } from '../redux/worldSlice';

export const GROUP_SINK_TOP = 'top';
export const GROUP_SINK_UNDER = 'under';

export const sinkGroups = [GROUP_SINK_TOP, GROUP_SINK_UNDER];

let sinkIndex = 0;

export const sinks: Finish[] = [
  //
  // TOP
  //

  {
    index: sinkIndex++,
    id: 'ONE-T34-T',
    name: 'ONE-T34',
    group: GROUP_SINK_TOP,
    image: 'images/one-t34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T40-T',
    name: 'ONE-T40',
    group: GROUP_SINK_TOP,
    image: 'images/one-t40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T50-T',
    name: 'ONE-T50',
    group: GROUP_SINK_TOP,
    image: 'images/one-t50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T34-17-T',
    name: 'ONE-T34-17',
    group: GROUP_SINK_TOP,
    image: 'images/one-t34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T17-34-T',
    name: 'ONE-T17-34',
    group: GROUP_SINK_TOP,
    image: 'images/one-t17-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T34-34-T',
    name: 'ONE-T34-34',
    group: GROUP_SINK_TOP,
    image: 'images/one-t34-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL34-T',
    name: 'ONE-TDL34',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdl34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-T',
    name: 'ONE-TDR34',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdr34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL40-T',
    name: 'ONE-TDL40',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdl40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR40-T',
    name: 'ONE-TDR40',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdr40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL50-T',
    name: 'ONE-TDL50',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdl50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR50-T',
    name: 'ONE-TDR50',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdr50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL17-34-T',
    name: 'ONE-TDL17-34',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdl34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-17-T',
    name: 'ONE-TDR34-17',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdr34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL34-34-T',
    name: 'ONE-TDL34-34',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdl34-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-34-T',
    name: 'ONE-TDR34-34',
    group: GROUP_SINK_TOP,
    image: 'images/one-tdr34-34.png',
  },

  //
  // UNDER
  //

  {
    index: sinkIndex++,
    id: 'ONE-T34-U',
    name: 'ONE-T34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T40-U',
    name: 'ONE-T40',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T50-U',
    name: 'ONE-T50',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T34-17-U',
    name: 'ONE-T34-17',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T17-34-U',
    name: 'ONE-T17-34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t17-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-T34-34-U',
    name: 'ONE-T34-34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-t34-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL34-U',
    name: 'ONE-TDL34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdl34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-U',
    name: 'ONE-TDR34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdr34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL40-U',
    name: 'ONE-TDL40',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdl40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR40-U',
    name: 'ONE-TDR40',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdr40.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL50-U',
    name: 'ONE-TDL50',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdl50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR50-U',
    name: 'ONE-TDR50',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdr50.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL17-34-U',
    name: 'ONE-TDL17-34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdl34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-17-U',
    name: 'ONE-TDR34-17',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdr34-17.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDL34-34-U',
    name: 'ONE-TDL34-34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdl34-34.png',
  },
  {
    index: sinkIndex++,
    id: 'ONE-TDR34-34-U',
    name: 'ONE-TDR34-34',
    group: GROUP_SINK_UNDER,
    image: 'images/one-tdr34-34.png',
  },
];
