import { Finish } from '../redux/worldSlice';

export const GROUP_SCENE = 'scene';
export const GROUP_TRACE = 'trace';
export const GROUP_GRID = 'grid';
export const groups = [GROUP_SCENE, GROUP_TRACE, GROUP_GRID];

let finishIndex = 0;

export const finishes: Finish[] = [
  {
    index: finishIndex++,
    id: 'HK_Clay',
    name: 'Clay',
    group: GROUP_SCENE,
    image: 'images/HK_Clay.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Herring',
    name: 'Herring',
    group: GROUP_SCENE,
    image: 'images/HK_Herring.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Hill',
    name: 'Hill',
    group: GROUP_SCENE,
    image: 'images/HK_Hill.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Honeycomb',
    name: 'Honeycomb',
    group: GROUP_SCENE,
    image: 'images/HK_Honeycomb.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Ice_Float',
    name: 'Ice Float',
    group: GROUP_SCENE,
    image: 'images/HK_Ice_Float.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Leaf',
    name: 'Leaf',
    group: GROUP_SCENE,
    image: 'images/HK_Leaf.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Lines',
    name: 'Lines',
    group: GROUP_SCENE,
    image: 'images/HK_Lines.png',
  },
  {
    index: finishIndex++,
    id: 'HK_Spruce',
    name: 'Spruce',
    group: GROUP_SCENE,
    image: 'images/HK_Spruce.png',
  },

  {
    index: finishIndex++,
    id: 'GW01',
    name: 'GW01',
    group: GROUP_TRACE,
    image: 'images/GW01.png',
  },
  {
    index: finishIndex++,
    id: 'GW02',
    name: 'GW02',
    group: GROUP_TRACE,
    image: 'images/GW02.png',
  },
  {
    index: finishIndex++,
    id: 'GW03',
    name: 'GW03',
    group: GROUP_TRACE,
    image: 'images/GW03.png',
  },
  {
    index: finishIndex++,
    id: 'GW04',
    name: 'GW04',
    group: GROUP_TRACE,
    image: 'images/GW04.png',
  },
  {
    index: finishIndex++,
    id: 'GW05',
    name: 'GW05',
    group: GROUP_TRACE,
    image: 'images/GW05.png',
  },
  {
    index: finishIndex++,
    id: 'GW06',
    name: 'GW06',
    group: GROUP_TRACE,
    image: 'images/GW06.png',
  },
  {
    index: finishIndex++,
    id: 'GW07',
    name: 'GW07',
    group: GROUP_TRACE,
    image: 'images/GW07.png',
  },

  {
    index: finishIndex++,
    id: 'MK01',
    name: 'MK01',
    group: GROUP_GRID,
    image: 'images/MK01.png',
  },
  {
    index: finishIndex++,
    id: 'MK02',
    name: 'MK02',
    group: GROUP_GRID,
    image: 'images/MK02.png',
  },
  {
    index: finishIndex++,
    id: 'MK03',
    name: 'MK03',
    group: GROUP_GRID,
    image: 'images/MK03.png',
  },
  {
    index: finishIndex++,
    id: 'MK04',
    name: 'MK04',
    group: GROUP_GRID,
    image: 'images/MK04.png',
  },
  {
    index: finishIndex++,
    id: 'MK05',
    name: 'MK05',
    group: GROUP_GRID,
    image: 'images/MK05.png',
  },
  {
    index: finishIndex++,
    id: 'MK06',
    name: 'MK06',
    group: GROUP_GRID,
    image: 'images/MK06.png',
  },
  {
    index: finishIndex++,
    id: 'MK07',
    name: 'MK07',
    group: GROUP_GRID,
    image: 'images/MK07.png',
  },
  {
    index: finishIndex++,
    id: 'MK08',
    name: 'MK08',
    group: GROUP_GRID,
    image: 'images/MK08.png',
  },
];
