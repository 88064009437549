import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { MantineProvider } from '@mantine/core';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        theme={{
          defaultRadius: 0,
          fontFamily:
            "Calibri, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          primaryColor: 'red',
          colors: {
            red: ['#d22630', '#d22630', '#d22630', '#d22630', '#d22630', '#d22630', '#d22630', '#d22630', '#d22630', '#d22630'],
          },
          headings: {
            fontFamily:
              "Calibri, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          },
        }}
      >
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log('Stalaverse 1.01');
